import { ref, watch, computed } from '@vue/composition-api'
import entities from '@/http/requests/entities'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import constants from '@/constants'

export default function useEntitiesList() {
  // Use toast
  const toast = useToast()

  const refEntitiesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Nombre', sortable: true },
    { key: 'cod', label: 'Codigo', sortable: true },
    { key: 'entity_type', label: 'Tipo', sortable: true },
    { key: 'status', label: 'Estado', sortable: true },
    { key: 'actions', label: 'Acciones' },
  ]
  const perPage = ref(10)
  const totalRows = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  // const roleFilter = ref(null)
  // const planFilter = ref(null)
  // const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refEntitiesListTable.value ? refEntitiesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    }
  })

  const refetchData = () => {
    refEntitiesListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchData = (ctx, callback) => {
    // store
    // .dispatch('app-user/fetchUsers', {
    //   q: searchQuery.value,
    //   perPage: perPage.value,
    //   page: currentPage.value,
    //   sortBy: sortBy.value,
    //   sortDesc: isSortDirDesc.value,
    // })

    entities.getAll()
      .then(response => {
        const { rows, total_rows } = response.data
        console.log(rows)

        callback(rows)
        // eslint-disable-next-line camelcase
        totalRows.value = total_rows
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error consultando la lista de Entidades!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // const resolveUserRoleVariant = role => {
  //   if (role === 'subscriber') return 'primary'
  //   if (role === 'author') return 'warning'
  //   if (role === 'maintainer') return 'success'
  //   if (role === 'editor') return 'info'
  //   if (role === 'admin') return 'danger'
  //   return 'primary'
  // }

  // const resolveUserRoleIcon = role => {
  //   if (role === 'subscriber') return 'UserIcon'
  //   if (role === 'author') return 'SettingsIcon'
  //   if (role === 'maintainer') return 'DatabaseIcon'
  //   if (role === 'editor') return 'Edit2Icon'
  //   if (role === 'admin') return 'ServerIcon'
  //   return 'UserIcon'
  // }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === constants.status.active) return 'success'
    if (status === constants.status.inactive) return 'secondary'
    return 'primary'
  }

  return {
    fetchData,
    tableColumns,
    perPage,
    currentPage,
    totalRows,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEntitiesListTable,

    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    // roleFilter,
    // planFilter,
    // statusFilter,
  }
}
