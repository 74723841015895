<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Agregar
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Nombre"
            rules="required"
          >
            <b-form-group
              label="Nombre"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="entityData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nombre"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Codigo"
            rules="required"
          >
            <b-form-group
              label="Codigo"
              label-for="code"
            >
              <b-form-input
                id="code"
                v-model="entityData.code"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="123"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!--  Entity Type -->
          <validation-provider
            #default="validationContext"
            name="Tipo"
            rules="required"
          >
            <b-form-group
              label="Tipo"
              label-for="entity_type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="entityData.entity_type"
                :options="entityTypeOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="entity_type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- primary
          <div>
            <b-card-text class="mb-0">
              Estado
            </b-card-text>
            <b-form-checkbox
              checked="true"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="BellIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="BellOffIcon" />
              </span>
            </b-form-checkbox>
          </div> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ulid } from 'ulid'
import { ref, watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import entities from '@/http/requests/entities'
import constants from '@/constants'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // BCardText,
    // BFormCheckbox,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    entityTypeOptions: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    // console.log('init Setup')
    const emptyData = {
      _id: '',
      name: '',
      code: '',
      type: 'entity',
      entity_type: '',
      image_url: '',
      status: constants.status.active,
      created_at: '',
      by: '',
    }

    const entityData = ref(JSON.parse(JSON.stringify(emptyData)))
    const resetData = () => {
      entityData.value = JSON.parse(JSON.stringify(emptyData))
    }

    const getById = async id => {
      // console.log(id)
      // console.log('await', await entities.getById(id))
      entityData.value = await entities.getById(id)

      // console.log('getById', entityData.value)
    }

    const addNewData = () => {
      entityData.value._id = ulid()
      entityData.value.created_at = new Date().toISOString()
      entityData.value.by = store.state.auth.user.id
    }
    const addEditData = () => {
      entityData.value.update_at = new Date().toISOString()
      entityData.value.by = store.state.auth.user.id
    }

    const onSubmit = () => {
      if (entityData.value._id) {
        addEditData()
      } else {
        addNewData()
      }
      // console.log(entityData.value)
      entities.create(entityData.value)
        .then(() => {
          // console.log('response', response)
          emit('refetch-data')
          emit('update:is-add-new-sidebar-active', false)
        })
    }

    watch(() => props.id, _ => {
      // console.log(
      //   'Watch props.id function called with args:',
      //   _,
      // )
      if (_) getById(_)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      entityData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      addNewData,
      getById,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
