// import querystring from 'querystring'
import axios from '../../axios/index'

export default {

  getAll() {
    return axios.get('/general/_design/tables/_view/entities?include_docs=true',
      {
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('userHash'),
          //  'withCredentials': 'true'
        },
      })
  },
  getById(id) {
    return axios.get(`/general/${id}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('userHash'),
          //  'withCredentials': 'true'
        },
      })
      .then(response => response.data).catch(err => { throw err })
  },
  create(obj) {
    return axios.post('/general/', obj,
      {
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('userHash'),
          // 'withCredentials': 'true'
        },
      })
  },
}
